<template>
  <div class="home">
    <router-link to="/account">Личный кабинет</router-link>
    <!--<router-link to="/calculate">Расчет параметров</router-link>
     <router-link to="/users">Пользователи</router-link>
    <router-link to="/users/id">Пользователь</router-link>
    <router-link to="/cities">Города</router-link>
    <router-link to="/cities/id">Город</router-link>
    <router-link to="/substances">Вещества</router-link>
    <router-link to="/substance/id">Вещество</router-link> 
    <router-link to="/login">Авторизация</router-link>
    <router-link to="/registration">Регистрация</router-link>-->
    <router-link to="/projects">Проекты</router-link>
    <!-- <router-link to="/projects/id">Проект</router-link> -->
    <router-link to="/tariffs">Тариф</router-link>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>

<style lang="scss">
.home {
  padding: 3.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 7.2rem);

  a {
    margin-bottom: 2rem;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    width: max-content;
  }
}
</style>
